import React from "react";
import type { HeadFC } from "gatsby";
import {Gibson} from "../../screens/BrandsScreen/Brands";

const GibsonBrandPage = () => {
    return <Gibson />;
};

export default GibsonBrandPage;

export const Head: HeadFC = () => <title>Our Brands</title>;
